export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

const baseUrl = process.env.REACT_APP_API_URL;

// api.js
export const fetchAnswers = async () => {
  const accessToken = getAccessToken();
  const response = await fetch(baseUrl + "answers/", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`, // JWT 토큰을 헤더에 포함
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch answers");
  }

  return await response.json(); // Return the JSON data
};

export const fetchAnswer = async (id) => {
  const accessToken = getAccessToken();
  const response = await fetch(baseUrl + `answers/${id}/`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch the answer");
  }
  const data = await response.json();
  console.log(data);
  return data;
};

export const uploadAnswer = async (subject, content) => {
  const accessToken = getAccessToken();
  const response = await fetch(baseUrl + "answers/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      subject: subject,
      content: content,
    }),
  });
  return response;
};

export const modifyAnswer = async (answer_id, content) => {
  const accessToken = getAccessToken();
  const response = await fetch(baseUrl + `answers/${answer_id}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ content: content }),
  });
  return response;
};

export const updateAnswerTitle = async (answerId, newTitle) => {
  const accessToken = getAccessToken();
  const response = await fetch(baseUrl + `answers/${answerId}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ subject: newTitle }),
  });

  if (!response.ok) {
    throw new Error("Failed to delete the answer");
  }
  return response;
};

export const togglePublicAccess = async (answer_id, isPublic) => {
  const accessToken = getAccessToken();
  const response = await fetch(baseUrl + `answers/${answer_id}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ is_public: !isPublic }), // Toggle public status
  });
  return response;
};

// api.js
export const deleteAnswer = async (id) => {
  const accessToken = getAccessToken();
  const response = await fetch(baseUrl + `answers/${id}/`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to delete the answer");
  }
  return response;
};

export const LogIn = async (email, password) => {
  const response = await fetch(baseUrl + "auth/login/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });

  if (!response.ok) {
    throw new Error("Login failed");
  }

  const data = await response.json();
  return data;
};

export const fetchUserInfo = async () => {
  const accessToken = getAccessToken();
  const response = await fetch(baseUrl + "auth/user-info/", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Login failed");
  }
  const data = await response.json();
  return data;
};

export const signUp = async (email, nickname, password) => {
  const response = await fetch(baseUrl + "auth/register/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      nickname,
      password,
    }),
  });
  console.log(response);
  if (!response.ok) {
    throw new Error("Login failed");
  }
  const data = await response.json();
  return data;
};

export const fetchPublicAnswer = async (id) => {
  const response = await fetch(baseUrl + `view/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch the answer");
  }
  const data = await response.json();
  return data;
};
