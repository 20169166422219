import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Flex,
  Text,
} from "@chakra-ui/react";
import { signUp } from "../api";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSignup = async () => {
    if (password !== confirmPassword) {
      setError("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      const data = await signUp(email, nickname, password);
      console.log(data);
      navigate("/login");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box p="6" rounded="md" shadow="md" width="400px" bg="white">
        <Heading as="h2" mb="6" textAlign="center">
          회원가입
        </Heading>

        {/* 이메일 입력 */}
        <FormControl mb="4">
          <FormLabel>이메일</FormLabel>
          <Input
            type="email"
            placeholder="이메일 입력"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        {/* 닉네임 입력 */}
        <FormControl mb="4">
          <FormLabel>닉네임</FormLabel>
          <Input
            placeholder="닉네임 입력"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
        </FormControl>

        {/* 비밀번호 입력 */}
        <FormControl mb="4">
          <FormLabel>비밀번호</FormLabel>
          <Input
            type="password"
            placeholder="비밀번호 입력"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>

        {/* 비밀번호 확인 입력 */}
        <FormControl mb="4">
          <FormLabel>비밀번호 확인</FormLabel>
          <Input
            type="password"
            placeholder="비밀번호 확인 입력"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FormControl>

        {/* 에러 메시지 */}
        {error && (
          <Text color="red.500" mb="4">
            {error}
          </Text>
        )}

        {/* 회원가입 버튼 */}
        <Button colorScheme="blue" width="full" mb="4" onClick={handleSignup}>
          회원가입
        </Button>

        {/* 로그인 페이지로 이동 */}
        <Button
          colorScheme="gray"
          width="full"
          onClick={() => navigate("/login")}
        >
          로그인으로 이동
        </Button>
      </Box>
    </Flex>
  );
};

export default Signup;
