import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // 라우팅을 위한 useNavigate 훅
import { fetchUserInfo, LogIn } from "../api";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // 페이지 이동을 위한 useNavigate 사용

  const handleLogin = async () => {
    try {
      const data = await LogIn(email, password);
      console.log(data);
      // JWT 토큰을 로컬 스토리지에 저장
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      const userData = await fetchUserInfo();
      localStorage.setItem("nickname", userData.nickname);

      // 로그인 성공 후 페이지 이동
      navigate("/answers");
    } catch (error) {
      setError("로그인에 실패했습니다. 다시 시도하세요.");
    }
  };

  const handleGuestUse = () => {
    // 로그인을 하지 않고 /answers로 라우팅
    navigate("/answers/new");
  };

  const handleSignup = () => {
    // 회원가입 페이지로 이동
    navigate("/signup");
  };

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box p="6" rounded="md" shadow="md" width="400px" bg="white">
        <Heading as="h2" mb="6" textAlign="center">
          로그인
        </Heading>

        {/* 이메일 입력 */}
        <FormControl mb="4">
          <FormLabel>이메일</FormLabel>
          <Input
            type="email"
            placeholder="이메일 입력"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        {/* 비밀번호 입력 */}
        <FormControl mb="4">
          <FormLabel>비밀번호</FormLabel>
          <Input
            type="password"
            placeholder="비밀번호 입력"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>

        {/* 로그인 버튼 */}
        <Button colorScheme="blue" width="full" mb="4" onClick={handleLogin}>
          로그인
        </Button>

        {/* 회원가입 이동 버튼 */}
        <Button colorScheme="teal" width="full" mb="4" onClick={handleSignup}>
          회원가입
        </Button>

        {/* 로그인을 하지 않고 사용하기 버튼 */}
        <Tooltip
          label="로그인 하면 답안지를 저장할 수 있어요!"
          aria-label="Tooltip"
        >
          <Button colorScheme="gray" width="full" onClick={handleGuestUse}>
            로그인 없이 사용하기
          </Button>
        </Tooltip>
      </Box>
    </Flex>
  );
};

export default Login;
