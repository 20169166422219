import React, { useRef, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Flex, Text, IconButton } from "@chakra-ui/react";
import Editor from "../components/Editor";
import Timer from "../components/Timer";
import SpecialCharacters from "../components/SpecialCharacters";
import Controls from "../components/Controls";
import ToolBar from "../components/ToolBar";
import { FaX } from "react-icons/fa6";
import { shortenWords } from "../utils";
import { fetchAnswer } from "../api";

const Answer = () => {
  const { id } = useParams(); // URL에서 answer id 또는 'new'를 가져옴
  const [answer, setAnswer] = useState(null); // 답안 상태
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(null); // 에러 상태
  const location = useLocation();
  const { examSubject, timeLimitOption, timeLimit } = location.state || {}; // 예행 연습에서 넘어온 상태
  const [selectedChar, setSelectedChar] = useState(""); // 선택된 특수문자
  const [isSpecialCharOpen, setIsSpecialCharOpen] = useState(false); // 특수문자 창 열기/닫기 상태
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false); // 전환 상태
  const editorRef = useRef();
  const textRef = useRef(null);

  const [scale, setScale] = useState(1); // 확대/축소 배율
  const editVersion = id !== undefined;

  // 확대 처리
  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2)); // 최대 2배 확대
  };

  // 축소 처리
  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // 최소 0.5배 축소
  };

  // 특수문자 창 토글 함수
  const toggleSpecialChar = () => {
    setIsSpecialCharOpen(!isSpecialCharOpen);
  };

  // 특수문자 선택 시 에디터에 반영
  const handleSpecialCharClick = (char) => {
    setSelectedChar(char);
    setIsSpecialCharOpen(false); // 특수문자 창 닫기
  };

  const togglePreviewMode = () => {
    setIsTransitioning(true); // 전환 효과 시작
    setTimeout(() => {
      setIsPreviewMode((prevMode) => !prevMode); // 미리보기 모드 전환
      setIsTransitioning(false); // 전환 효과 종료
    }, 300); // 전환 시간을 300ms로 설정
  };

  useEffect(() => {
    const loadAnswer = async () => {
      if (!editVersion) {
        // New answer case: don't fetch anything, stop loading immediately
        setLoading(false);
        return;
      }

      try {
        const data = await fetchAnswer(id);
        setAnswer(data); // 응답 데이터로 상태 업데이트
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false); // 로딩 완료
      }
    };

    loadAnswer();
  }, [id]); // id가 변경될 때마다 다시 실행

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  const isLoggedIn = !!localStorage.getItem("access_token"); // 로그인 여부 확인

  const nickname = isLoggedIn
    ? localStorage.getItem("nickname") || "비회원"
    : "비회원";

  const title =
    nickname === "비회원"
      ? "연습" // 비회원일 때 제목은 "연습"
      : !editVersion
      ? examSubject // 새 답안일 경우 제목은 시험 과목
      : answer.subject; // 기존 답안일 경우 제목은 답안의 제목

  return (
    <Flex direction="column">
      <Flex
        width="full"
        position="fixed"
        top="0"
        zIndex="1000"
        padding="20px"
        justify="space-between"
        align="center"
        backgroundColor="#00438e"
        height="60px"
      >
        <Flex gap="10px" alignItems="center">
          <Text color="gold">닉네임</Text>
          <Text color="white" fontSize="24px">
            {nickname}
          </Text>
        </Flex>
        <Text fontSize="36px" color="white">
          {shortenWords(title)}
        </Text>
        <Timer timeLimitOption={timeLimitOption} timeLimit={timeLimit} />
      </Flex>

      <Flex align="center" justify="center" backgroundColor="gray.100">
        {/* 본문 */}
        <ToolBar
          toggleSpecialChar={toggleSpecialChar}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          isPreviewMode={isPreviewMode}
          isTransitioning={isTransitioning}
        />

        {/* 특수문자 팝업 (툴바 오른쪽) */}
        {isSpecialCharOpen && (
          <Box
            width="200px"
            position="fixed"
            left="100px"
            top="170px"
            bg="white"
            p="4"
            border="1px solid gray"
            boxShadow="md"
            zIndex="1001"
          >
            <IconButton
              aria-label="Close"
              icon={<FaX />}
              onClick={() => setIsSpecialCharOpen(false)}
              position="absolute"
              top="4px"
              right="4px"
              size="sm"
              variant="ghost"
            />
            <Box height="20px" />
            <SpecialCharacters onCharacterClick={handleSpecialCharClick} />
          </Box>
        )}

        <Editor
          ref={editorRef}
          specialChar={selectedChar}
          scale={scale}
          isPreviewMode={isPreviewMode}
          isTransitioning={isTransitioning}
          editorRef={textRef}
          fetchedContent={id === "new" ? "" : answer?.content} // New: empty editor, Existing: fetched content
        />

        {/* 하단 버튼 */}
        <Flex
          justify="flex-end"
          position="fixed"
          bottom="0"
          left="0"
          right="0"
          bg="white"
          p="2"
          borderTop="1px solid gray"
          zIndex="1000"
        >
          <Controls
            togglePreviewMode={togglePreviewMode}
            isPreviewMode={isPreviewMode}
            textRef={textRef}
            editorRef={editorRef}
            subject={examSubject}
            editVersion={editVersion}
            answer_id={answer?.answer_id}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Answer;
