import React from "react";
import { Navigate } from "react-router-dom";

// Function to check if the user is authenticated
const isAuthenticated = () => {
  return localStorage.getItem("access_token") !== null;
};

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/login" />;
  }

  // Otherwise, allow access to the protected route
  return children;
};

export default ProtectedRoute;
