import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Flex,
  Button,
  List,
  Text,
  Select,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  MenuDivider,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Grid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useDisclosure,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { FaQuestionCircle } from "react-icons/fa"; // react-icons 사용
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import AnswerListItem from "../components/AnswerListItem";
import { fetchAnswers } from "../api";

const AnswerList = () => {
  const [answers, setAnswers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [examSubject, setExamSubject] = useState("");
  const [timeLimitOption, setTimeLimitOption] = useState("");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const loadAnswers = async () => {
      try {
        const data = await fetchAnswers(); // Call the function from api.js
        setAnswers(data); // Update the state with fetched answers
      } catch (error) {
        console.error("Error fetching answers:", error);
      }
    };

    loadAnswers();
  }, []);

  const handleCreateAnswer = () => {
    if (examSubject === "") {
      alert("시험과목을 입력해주세요.");
      return;
    }
    // 제한시간을 '시:분:초' 형식으로 저장
    const timeLimit = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    navigate("/answers/new", {
      state: { examSubject, timeLimitOption, timeLimit },
    });
    onClose();
  };

  const handleLogout = () => {
    // 로그아웃 시 로컬 스토리지에서 토큰 삭제
    localStorage.removeItem("access_token");
    navigate("/login"); // 로그인 페이지로 이동
  };

  const handleProfile = () => {
    navigate("/profile"); // 프로필 페이지로 이동
  };

  return (
    <Flex direction="column" align="center" justify="center">
      <Flex
        as="header"
        width="100%"
        padding="10px"
        justifyContent="space-between"
        alignItems="center"
        bg="blue.600"
        color="white"
      >
        <Heading size="lg">ibt 연습장</Heading>

        {/* 유저 프로필 메뉴 */}
        <Flex>
          <Tooltip
            label={`개발자 연락처 : nhheo16@gmail.com \n 문의사항 건의사항 대환영`}
            aria-label="Contact tooltip"
          >
            <IconButton
              mb="4"
              icon={<FaQuestionCircle />}
              background="transparent"
            />
          </Tooltip>
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="blue"
              rightIcon={<ChevronDownIcon />}
            >
              <Flex alignItems="center">
                <Avatar
                  size="sm"
                  name={localStorage.getItem("nickname")}
                  mr="2"
                />
                <Text>프로필</Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleProfile} color="black">
                프로필 보기
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogout} color="red.500">
                로그아웃
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Box p="6" rounded="md" shadow="md" width="600px" bg="white">
        <Heading as="h2" mb="6" textAlign="center">
          작성한 답안지 리스트
        </Heading>

        {/* 답안지 리스트 */}
        <List spacing={3}>
          {answers.map((answer) => (
            <AnswerListItem
              answer={answer}
              answers={answers}
              setAnswers={setAnswers}
            />
          ))}
        </List>

        {/* 새 답안지 만들기 버튼 */}
        <Button mt="6" colorScheme="green" width="full" onClick={onOpen}>
          새 답안지 만들기
        </Button>

        {/* 새 답안지 만들기 모달 */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>새 답안지 만들기</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb="4">
                <FormLabel>시험과목</FormLabel>
                <Input
                  placeholder="시험과목 입력"
                  value={examSubject}
                  onChange={(e) => setExamSubject(e.target.value)}
                />
              </FormControl>

              <FormControl mb="4">
                <FormLabel>제한시간 선택</FormLabel>
                <Select
                  placeholder="제한시간 선택"
                  value={timeLimitOption}
                  onChange={(e) => setTimeLimitOption(e.target.value)}
                >
                  <option value="none">없음</option>
                  <option value="set">설정 시간</option>
                  <option value="cumulative">누적 시간</option>
                </Select>
              </FormControl>

              {/* 설정 시간 옵션이 선택되었을 때 시간 입력란 표시 */}
              {timeLimitOption === "set" && (
                <FormControl mb="4">
                  <FormLabel>제한시간 설정</FormLabel>
                  <Grid
                    templateColumns="repeat(4, 1fr)"
                    gap={4}
                    alignItems="center"
                  >
                    {/* 시 입력 */}
                    <NumberInput
                      max={23}
                      min={0}
                      value={hours}
                      onChange={(valueString) =>
                        setHours(parseInt(valueString))
                      }
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text textAlign="center">시간</Text>

                    {/* 분 입력 */}
                    <NumberInput
                      max={59}
                      min={0}
                      value={minutes}
                      onChange={(valueString) =>
                        setMinutes(parseInt(valueString))
                      }
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text textAlign="center">분</Text>
                  </Grid>
                </FormControl>
              )}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr="3" onClick={handleCreateAnswer}>
                완료
              </Button>
              <Button onClick={onClose}>취소</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default AnswerList;
