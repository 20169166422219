import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import AnswerList from "./pages/AnswerList";
import Answer from "./pages/Answer";
import View from "./pages/View";
import ProtectedRoute from "./components/ProtectedRoute";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/answers/new" element={<Answer />} />

        {/* Protected Routes */}
        <Route
          path="/answers"
          element={
            <ProtectedRoute>
              <AnswerList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/answers/:id"
          element={
            <ProtectedRoute>
              <Answer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view/:id"
          element={<View />} // Assuming view is public
        />

        {/* Default route: Redirect to /answers if authenticated, otherwise to /login */}
        <Route
          path="*"
          element={
            isAuthenticated() ? (
              <Navigate to="/answers" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

const isAuthenticated = () => {
  return localStorage.getItem("access_token") !== null;
};

export default App;
